.content {
    width: 60%;
}

.title {
    font-family: 'Montserrat';
    font-size: 40px;
}

.wrap {
    box-shadow: 20px 20px 45px 5px black;
    border-radius: 20px;
    margin: 5vh;
}

.side {
    width: 20%;
}

.fill {
    width: 20%;
}

.comittees {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.info {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding: 5vh;
    text-align: left;
    font-size: 20px;
}

.section-title {
    font-family: 'Avenir';
    font-size: 30px;
}

.subtitle {
    font-size: 27px;
    color: gray;
}

.block {
    display: flex;
    flex-direction: column;
}

@media only screen and (max-width: 700px) {
    .comittees {
        flex-direction: column;
    }

    .content {
        width: 100%;
    }

    .wrap {
        box-shadow: none;
        border-radius: 20px;
        margin: 0vh;
    }

    .block {
        border-bottom: 1px solid black;
    }

    .side {
        width: 100%;
    }

    .info {
        flex-direction: column;
    }
}