.sidebar {

}

.title {
    font-size: 20px;
    font-family: 'Avenir';
    text-align: right;
    margin-right: 10px;
}

.links {
    display: flex;
    flex-direction: column;
}

.link-item {
    text-decoration: none;
    padding: 10px;
    color: purple;
    text-align: right;
}

.link-item:hover {
    animation: nav-hover .3s ease-in;
    color: rgb(213, 138, 0);
}

@media only screen and (max-width: 700px) {
    .links {
        flex-direction: row;
        justify-content: center;
    }

    .title {
        text-align: center;
        margin-right: 0px;
    }

    .sidebar {
        width: 100%;
    }
}

@keyframes nav-hover {
    0% {
        color: purple;
    }

    100% {
        color: rgb(213, 138, 0);
    }
}