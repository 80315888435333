iframe {
    width: 560px;
    height: 315px;
    padding: 5vh;
    box-shadow: 20px 20px 45px 5px black;
    border-radius: 15px;
    margin-bottom: 10vh;
}

.pg {
    text-align: left;
    font-size: 20px;
    font-family: 'Avenir';
    margin-left: 20vh;
    margin-right: 20vh;
    margin-bottom: 20vh;
    line-height: 30px;
    box-shadow: 20px 20px 45px 5px black;
    padding: 5vh;
    border-radius: 15px;
    background-color: white;
}

.title {
    font-family: 'Montserrat';
    font-size: 40px;
}

select {
    border: none;
    border-radius: 15px;
    margin: 4vh;
    border: 1px solid black;
    padding: 1vh;
}

@media only screen and (max-width: 700px) {
    .pg {
        margin: 0vh;
        background-color: whitesmoke;
        box-shadow: none;
        font-size: 15px;
    }

    iframe {
        width: 280px;
        height: 157.5px;
        padding: 0vh;
        box-shadow: none;
        margin-bottom: 2vh;
    }
}