.wrapper {
    width: 100%;
    display: flex;
    text-align: left;
    justify-content: space-between;
    align-items: center;
    height: 35vh;
    border-bottom: 1px solid black;
    font-size: 20px;
}

.title {
    font-size: 30px;
}

.form {
    display: flex;
    justify-content: center;
}