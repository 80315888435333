.nav-item {
    font-family: 'Avenir';
    text-decoration: none;
    padding: 15px;
    font-size: 22px;
    border-radius: 2px;
    color: rgb(79, 79, 79);
    line-height: 70px;
    font-weight: 30%;
}

ul {
    list-style: none;
}

.nav-item:hover {
    animation: nav-hover .3s ease-in;
    background-color: rgb(213, 138, 0);
    color: white;
}


.logo {
    height: 20vh;
    width: 100%;
    background-image: url('./../../../Pictures/TopLogo.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}

.small-logo {
    background-image: url('./../../../Pictures/Logo_Option2.png');
    height: 10vh;
    background-size: contain;
    width: 15vh;
    margin-bottom: 2vh;
    background-repeat: no-repeat;
}

nav {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.donate {
    width: 15vh;
    margin-top: 3vh;
}

.donate a {
    background-color: purple;
    color: white;
    text-decoration: none;
    padding: 15px;
}

.mobile-header {
    display: none;
}

.routes {
    display: flex;
    justify-content: center;
}

.subnav-content {
    display: none;
    z-index: 1;
    position: absolute;
}

.subnav-item {
    font-family: 'Avenir';
    text-decoration: none;
    padding: 16px;
    font-size: 22px;
    border-radius: 2px;
    color: white;
    line-height: 70px;
    font-weight: 30%;
    background-color: rgba(128, 0, 128, 0.449);
    border-radius: 5px;
    
}

.subnav:hover > .subnav-content {
    animation: dropdown .3s ease-out;
    display: block;
}

.subnav-content a:hover {
    animation: subnav-hover .3s ease-in-out;
    padding: 15px;
    border: 1px solid rgb(128, 0, 128);
    background-color: white;
    color: rgb(128, 0, 128);
}

@keyframes dropdown {
    0% {
        transform: translateY(-5vh);
        display: none;
    }

    100% {
        transform: translateY(0vh);
        display: block;
    }
}

@media only screen and (max-width: 700px) {
    nav {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        justify-content: center;
        width: 100%;
    }

    .routes a {
        font-size: 15px;
    }

    .nav-item {
        padding: 10px;
    }

    .small-logo {
        display: none;
    }

    .donate {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .mobile-header {
        display: block;
    }

    .header {
        display: none;
    }

    .dropdown {
        width: 100%;
        height: 5vh;
        background-color: rgb(213, 138, 0);
        color: white;
        border: none;
    }
}

@keyframes nav-hover {
    from {
        background-color: inherit;
        color: inherit;
    }
    to {
        background-color: rgb(213, 138, 0);
        color: white;
    }
}

@keyframes subnav-hover {
    from {
        background-color: rgba(128, 0, 128, 0.449);
        color: white;
    }
    to {
        background-color: white;
        color: rgb(128, 0, 128);
        border: 1px solid rgb(128, 0, 128);
    }
}