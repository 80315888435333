.council {
    display: flex;
    justify-content: center;
}

.content {
    width: 60%;
}

.wrap {
    box-shadow: 20px 20px 45px 5px black;
    border-radius: 20px;
    margin: 5vh;
    padding-left: 5vh;
    padding-right: 5vh;
}

.fill {
    width: 20%;
}

.side {
    width: 20%;
}

.page-title {
    font-family: 'Montserrat';
    font-size: 40px;
}

.wrapper {
    width: 100%;
    display: flex;
    text-align: left;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2vh;
    white-space: nowrap;
    font-size: 20px;
}

.title {
    font-size: 30px;
}

.pic {
    display: flex;
    flex-direction: row;
}

@media only screen and (max-width: 700px) {
    .council {
        flex-direction: column;
    }

    .content {
        width: 100%;
        padding: 0px;
        box-shadow: none;
        border-radius: 20px;
        margin: 0vh;
    }

    .wrap {
        width: 100%;
        padding: 0px;
        box-shadow: none;
        border-radius: 20px;
        margin: 0vh;
    }

    .wrapper {
        flex-direction: column;
        align-items: center;
    }

    .title {
        text-align: center;
    }

    .side {
        width: 100%;
    }
}