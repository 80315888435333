.outline {
    height: 100%;
}

.donate {
    width: 100%;
    margin-top: 10vh;
    display: none;
    margin-bottom: 10vh;
}

.donate a {
    background-color: purple;
    color: white;
    text-decoration: none;
    padding: 15px;
    width: 15vh;
}

@media only screen and (max-width: 700px) {
    .donate {
        display: flex;
        justify-content: center;
    }
}