.history {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.title {
    font-family: 'Montserrat';
    font-size: 40px;
}

.side {
    width: 20%;
}

.content {
    width: 60%;
}

.fill {
    width: 20%;
}

.pg {
    font-size: 20px;
    font-family: "Avenir";
    padding: 5vh;
    border-radius: 20px;
    line-height: 35px;
    margin: 10vh;
    box-shadow: 20px 20px 45px 5px black;
    background-color: white;
}

@media only screen and (max-width: 700px) {
    .history {
        flex-direction: column;
    }

    .side {
        width: 100%;
    }

    .content {
        width: 100%;
    }

    .pg {
        width: 100%;
        margin: 0vh;
        padding: 0;
        border-radius: 0;
        margin-bottom: 5vh;
        box-shadow: none;
        background-color: whitesmoke;
    }
}