.alumni {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.title {
    font-family: 'Montserrat';
    font-size: 40px;
}

.content {
    width: 60%;
}

.side {
    width: 20%;
}

.fill {
    width: 20%;
}

.composites {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10vh;
    box-shadow: 20px 20px 45px 5px black;
    border-radius: 20px;
    margin: 5vh;
    padding-top: 5vh;
    padding-bottom: 5vh;
}

.composite-nav {
    background-color: whitesmoke;
    border: none;
    font-size: 40px;
    color: black;
}

.composites img {
    width: 600px;
    height: 400px;
}

@keyframes slide {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
        z-index: -1;
    }
}

@media only screen and (max-width: 1150px) {
    .composites img {
        width: 350px;
        height: 200px;
    }
}

@media only screen and (max-width: 700px) {
    .alumni {
        flex-direction: column;
    }

    .fill {
        display: none;
    }

    .side {
        width: 100%;
    }

    .content {
        width: 100%;
    }

    .composites {
        box-shadow: none;
        border-radius: 20px;
        margin: 0vh;
    }

    .composites img {
        width: 250px;
        height: 170px;
    }
}